import {
	Box,
	Container,
	FormLabel,
	Grid,
	MenuItem,
	Select,
} from '@mui/material';
import SideBar from 'app/components/Sidebar';
import UserRightBar from 'app/components/UserRightBar';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { useInjectSaga } from 'utils/redux-injectors';

import Header from 'app/components/Header';
import Content from 'app/components/content';
import EditableTable from './containers/TranslationTable';
import { TranslationsRepoSaga } from './redux/saga';
import {
	selectError,
	selectIsLoading,
	selectLanguageData,
} from './redux/selectors';
import {
	actions,
	reducer as reducerTranslations,
	sliceKey as sliceKeyTranslations,
} from './redux/slice';
import { useStyles } from './style';
import Loader from 'app/components/Loader';
import AddRowButton from './containers/AddRowButton';
import { t } from 'i18next';
export const tableBody = [
	{ title: 'Feature', field: 'Feature', isEdit: true },
	{ title: 'English value', field: 'Source', isEdit: false },
	{ title: 'Translation value', field: 'Translation', isEdit: true },
];
export const languages = [{ language: 'en' }, { language: 'he' }];

const Translations = () => {
	useInjectReducer({ key: sliceKeyTranslations, reducer: reducerTranslations });
	useInjectSaga({ key: sliceKeyTranslations, saga: TranslationsRepoSaga });

	const dispatch = useDispatch();
	const [selectedLanguage, setSelectedLanguage] = useState('');
	const useError = useSelector(selectError);
	const useLanguageData = useSelector(selectLanguageData);
	const useIsLoading = useSelector(selectIsLoading);

	const styles = useStyles();

	const onChangeLanguage = e => {
		const _language = e.target.value;
		setSelectedLanguage(_language);
		dispatch(
			actions.getTranslation({
				language: _language,
			}),
		);
	};

	const handleSave = allRows => {
		dispatch(
			actions.saveTranslation({
				data: allRows,
				language: useLanguageData?.language,
			}),
		);
	};
	return (
		<Box display="flex" alignItems={'center'}>
			<Header />
			<Content>
				<Grid container spacing={2} className={clsx(styles.root)}>
					<Grid item lg={12} xs={12}>
						<Box display="flex" style={{ height: '100%' }}>
							<SideBar />
							<Container
								maxWidth={'xl'}
								sx={{ height: 'calc( 100vh - 80px )', overflowY: 'scroll' }}
							>
								<Box className={clsx(styles.wrapper)}>
									<Box className={clsx([styles.buttonsWrapper])}>
										<Box className={clsx([styles.formControl])}>
											<FormLabel>{t('Choose language')}</FormLabel>
											<Select
												value={selectedLanguage}
												onChange={e => onChangeLanguage(e)}
												className={clsx(styles.selectInput)}
											>
												{languages?.map(option => (
													<MenuItem
														value={option?.language}
														key={option?.language}
													>
														{option?.language}
													</MenuItem>
												))}
											</Select>
										</Box>
										<AddRowButton />
									</Box>
									<p>{useError}</p>
									<Box component="div" className={clsx(styles.TableBox)}>
										<EditableTable
											tableData={useLanguageData?.translationData || []}
											handleSave={handleSave}
											tableBody={tableBody}
										/>
										{useIsLoading && <Loader />}
									</Box>
								</Box>
							</Container>
							<UserRightBar />
						</Box>
					</Grid>
				</Grid>
			</Content>
		</Box>
	);
};

export default Translations;
