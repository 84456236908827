import {
	Box,
	Button,
	ButtonGroup,
	InputLabel,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconEdit, IconTrash, IconTrashRed } from '../../../../assets/images';

import { Tooltip } from '@mui/material';
import { selectPromoCodeList, selectSelectedPromoCode } from '../selectors';
import { actions } from '../slice';
import CustomModal from 'app/components/CustomModal';
import { useStyles } from '../style';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common,
		color: '#645B70',
		fontWeight: '400',
		background: 'transparent',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		fontWeight: '500',
		maxWidth: 100,
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:hover': {
		backgroundColor: '#ece6f4',
		cursor: 'pointer',
		'& .MuiTableCell-root': {
			color: theme.palette.primary.main,
		},
	},
}));

const PromoCodesListTable = () => {
	const [deletePopup, setDeletePopup] = useState(false);
	const usePromoCodeList = useSelector(selectPromoCodeList);
	const useSelectedPromoCode = useSelector(selectSelectedPromoCode);
	const dispatch = useDispatch();
	const handleOpenEdit = promoCode => {
		dispatch(actions.selectedPromoCode(promoCode));
		dispatch(actions.toggleAddPromoCodePopup());
		dispatch(actions.isEditAction(true));
	};

	const handleOpenDelete = promoCode => {
		dispatch(actions.selectedPromoCode(promoCode));
		setDeletePopup(true);
	};
	const onPromoCodeDeleteSuccess = () => {
		setDeletePopup(false);
		dispatch(actions.cleanFormPromoCode());
		dispatch(actions.toggleAddPromoCodePopup());
		dispatch(actions.cleanFormPromoSelected());
		dispatch(actions.isEditAction(false));
	};
	const handleDelete = id => {
		dispatch(
			actions.deletePromoCode({
				id: id,
				callback: onPromoCodeDeleteSuccess,
			}),
		);
	};
	const styles = useStyles();

	const [order, setOrder] = useState<'asc' | 'desc'>('asc');
	const [orderBy, setOrderBy] = useState('company_name');

	const handleRequestSort = property => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const sortedPromoCodeList = [...usePromoCodeList]?.sort((a, b) => {
		const valueA = a[orderBy] || '';
		const valueB = b[orderBy] || '';
		if (valueA < valueB) return order === 'asc' ? -1 : 1;
		if (valueA > valueB) return order === 'asc' ? 1 : -1;
		return 0;
	});

	return (
		<>
			<Table
				sx={{ minWidth: 700 }}
				aria-label="sortable table"
				style={{ width: '100%', overflowX: 'auto' }}
			>
				<TableHead>
					<TableRow>
						<TableCell align="center" style={{ maxWidth: '10%' }}>
							<TableSortLabel
								active={orderBy === 'company_name'}
								direction={orderBy === 'company_name' ? order : 'asc'}
								onClick={() => handleRequestSort('company_name')}
							>
								Company Name
							</TableSortLabel>
						</TableCell>
						<TableCell align="center">
							<TableSortLabel
								active={orderBy === 'promo_code'}
								direction={orderBy === 'promo_code' ? order : 'asc'}
								onClick={() => handleRequestSort('promo_code')}
							>
								Promo Code
							</TableSortLabel>
						</TableCell>
						<TableCell align="center">
							<TableSortLabel
								active={orderBy === 'clinic'}
								direction={orderBy === 'clinic' ? order : 'asc'}
								onClick={() => handleRequestSort('clinic')}
							>
								Clinic
							</TableSortLabel>
						</TableCell>
						<StyledTableCell align="center">D / M</StyledTableCell>
						<StyledTableCell align="center">Amount of d/m</StyledTableCell>
						<StyledTableCell align="center" style={{ maxWidth: 10 }}>
							Link
						</StyledTableCell>
						<StyledTableCell align="center">Price</StyledTableCell>
						<StyledTableCell align="center">Used</StyledTableCell>
						<StyledTableCell align="center">Actions</StyledTableCell>
					</TableRow>
				</TableHead>
				{sortedPromoCodeList && sortedPromoCodeList.length > 0 && (
					<TableBody>
						{sortedPromoCodeList.map((promoCode, index) => {
							return (
								<StyledTableRow
									key={index}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
								>
									<StyledTableCell align="left">
										{promoCode.company_name}
									</StyledTableCell>
									<StyledTableCell align="center">
										{promoCode.promo_code}
									</StyledTableCell>
									<StyledTableCell align="center">
										{promoCode?.clinic}
									</StyledTableCell>
									<StyledTableCell align="center">
										{promoCode?.days_months}
									</StyledTableCell>
									<StyledTableCell align="center">
										{promoCode?.number_of_days_months}
									</StyledTableCell>
									<StyledTableCell align="center">
										{promoCode?.payment_link}
									</StyledTableCell>
									<StyledTableCell align="center">
										{promoCode?.price_per_patient_per_month}
									</StyledTableCell>
									<StyledTableCell align="center">
										{promoCode?.setPractitioner}
									</StyledTableCell>
									<StyledTableCell
										align="left"
										sx={{ width: '15%', textAlign: 'center' }}
									>
										<ButtonGroup
											className="iconBtnGroup"
											variant="text"
											aria-label="text button group"
											sx={{ justifyContent: 'flex-end' }}
										>
											<Button
												onClick={() => {
													handleOpenEdit(promoCode);
												}}
											>
												<Tooltip title={t('Edit')} placement="top">
													<img src={IconEdit} alt={t('edit')} />
												</Tooltip>
											</Button>
											<Button
												onClick={() => {
													handleOpenDelete(promoCode);
												}}
											>
												<Tooltip title={t('Trash')} placement="top">
													<img src={IconTrash} alt={t('trash')} />
												</Tooltip>
											</Button>
										</ButtonGroup>
									</StyledTableCell>
								</StyledTableRow>
							);
						})}
					</TableBody>
				)}
			</Table>
			{usePromoCodeList.length === 0 && (
				<Box
					style={{
						height: '45vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<p>{t('No records found')}</p>
				</Box>
			)}
			<CustomModal
				btnTxt={t('Delete')}
				openModal={deletePopup}
				setOpen={setDeletePopup}
				Icon={IconTrashRed}
				heading={t('Delete')}
				secondHeading={t('You are about to delete a promo code')}
				modalClass={[styles.TrashMClass]}
				onSubmit={() => {
					handleDelete(useSelectedPromoCode._id);
				}}
			>
				<InputLabel className={styles.label}>{t('Promo code')}</InputLabel>
				<TextField
					className={styles.input}
					InputLabelProps={{ style: { color: '#645B70' } }}
					fullWidth
					defaultValue={useSelectedPromoCode.promo_code}
					id="fullWidth"
					disabled
					onKeyDown={(e: any) => {
						if (e.key === 'Enter') {
							e.preventDefault();
						}
					}}
				/>
			</CustomModal>
		</>
	);
};

export default PromoCodesListTable;
