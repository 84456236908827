import {
	Alert,
	AlertTitle,
	Box,
	Button,
	InputLabel,
	TextField,
} from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from '../style';
import CustomModal from 'app/components/CustomModal';
import { IconBluePlus } from 'assets/images';
import { actions } from '../redux/slice';
import {
	selectError,
	selectFormAddNewValue,
	selectOpenAddNewValuePopup,
} from '../redux/selectors';
import { t } from 'i18next';
export const languages = [
	{ language: 'Feature', inputLabel: 'Feature (Only for admin table)' },
	{ language: 'Source', inputLabel: 'Source value (not display)' },
	{ language: 'en', inputLabel: 'en value (display)' },
	{ language: 'he', inputLabel: 'he value (display)' },
];

const AddRowButton = () => {
	const dispatch = useDispatch();
	const styles = useStyles();
	const useOpenAddNewValuePopup = useSelector(selectOpenAddNewValuePopup);
	const useFormAddNewValue = useSelector(selectFormAddNewValue);
	const useError = useSelector(selectError);

	const openAddNewValuePopup = () => {
		dispatch(actions.openAddNewValuePopup(true));
	};
	const onSubmit = () => {
		dispatch(actions.addValueToTranslationData(useFormAddNewValue));
	};
	const handleFieldChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = evt.target;
		dispatch(actions.updateFormAddNewValue({ key: name, value }));
	};
	return (
		<Box className={clsx(styles.buttonBox)}>
			<Button
				onClick={() => openAddNewValuePopup()}
				className={clsx(styles.btn)}
				variant="contained"
			>
				{t('Add value')}
			</Button>
			<CustomModal
				btnTxt={t('Save')}
				openModal={useOpenAddNewValuePopup}
				setOpen={() => {
					dispatch(actions.openAddNewValuePopup(false));
				}}
				Icon={IconBluePlus}
				heading={t('Add a new value')}
				secondHeading={t('Be sure to follow the instructions closely')}
				modalClass={[styles.patient]}
				onSubmit={() => {
					onSubmit();
				}}
			>
				<Box component={`div`} className={styles.formGroup}>
					{useError && (
						<Alert severity="error">
							<AlertTitle sx={{ padding: '0 10px' }}>{useError}</AlertTitle>
						</Alert>
					)}
					{languages.map((language, index) => (
						<Box key={index}>
							<InputLabel className={styles.label}>
								{language.inputLabel}
							</InputLabel>
							<TextField
								className={styles.input}
								InputLabelProps={{ style: { color: '#645B70' } }}
								fullWidth
								placeholder=""
								id={language.language}
								value={useFormAddNewValue[language.language]}
								onChange={handleFieldChange}
								name={language.language}
							/>
						</Box>
					))}
				</Box>
			</CustomModal>
		</Box>
	);
};

export default AddRowButton;
