import React from 'react';
import { SliderThumb } from '@mui/material';
import { t } from 'i18next';

interface SliderThumbComponentProps extends React.HTMLAttributes<unknown> {}
function SliderThumbComponent(props: SliderThumbComponentProps) {
	const { children, ...other } = props;
	let sliderLabel: string = 'Low';

	switch ((other['ownerState'] as any).value) {
		case 0: {
			sliderLabel = t('Low');
			break;
		}

		case 1: {
			sliderLabel = t('No preference');
			break;
		}

		case 2: {
			sliderLabel = t('High');
			break;
		}

		default: {
			sliderLabel = t('Low');
			break;
		}
	}

	return (
		<SliderThumb {...other}>
			{children}
			<span>{sliderLabel}</span>
		</SliderThumb>
	);
}

export default SliderThumbComponent;
