import { t } from 'i18next';
import { call, put, takeLatest } from 'redux-saga/effects';
import { API_URL } from 'utils/constants';
import { request } from 'utils/request';
import { actions, initialState } from './slice';

export function* getTranslation(action) {
	yield put(actions.setIsLoading(true));

	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/translation/admin/get-language`,
			options,
		);
		if (response && response.messageCode !== 'MESSAGE.GET_LANGUAGE.SUCCESS') {
			if (!response?.message) {
				yield put(actions.setGatLanguageError(t('something went wrong')));
			} else {
				yield put(actions.setGatLanguageError(t(`${response.message}`)));
			}
		} else {
			yield put(actions.setGatLanguageError(''));
			yield put(actions.setLanguageData(response?.data));
		}
		yield put(actions.setIsLoading(false));
	} catch (e: any) {
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
		yield put(actions.setGatLanguageError(errorMessage));
		yield put(actions.setLanguageData(initialState.languageData));
		yield put(actions.setIsLoading(false));
	}
}

export function* saveTranslation(action) {
	yield put(actions.setIsLoading(true));

	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};
	try {
		const response = yield call(
			request,
			`${API_URL}/translation/save-translation`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.SAVE_TRANSLATION.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setGatLanguageError(t('something went wrong')));
			} else {
				yield put(actions.setGatLanguageError(t(`${response.message}`)));
			}
		} else {
			yield put(actions.setGatLanguageError(''));
			yield put(actions.setLanguageData(response?.data));
		}
		yield put(actions.setIsLoading(false));
	} catch (e: any) {
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
		yield put(actions.setGatLanguageError(errorMessage));
		yield put(actions.setLanguageData(initialState.languageData));
		yield put(actions.setIsLoading(false));
	}
}

export function* addValueToTranslationData(action) {
	yield put(actions.setIsLoading(true));

	const options = {
		method: 'POST',
		body: JSON.stringify(action?.payload),
	};

	try {
		const response = yield call(
			request,
			`${API_URL}/translation/add-value-to-translation-data`,
			options,
		);
		if (
			response &&
			response.messageCode !== 'MESSAGE.ADD_TRANSLATION.SUCCESS'
		) {
			if (!response?.message) {
				yield put(actions.setGatLanguageError(t('something went wrong')));
			} else {
				yield put(actions.setGatLanguageError(t(`${response.message}`)));
			}
		} else {
			yield put(actions.setGatLanguageError(''));
			yield put(actions.setLanguageData(response?.data));
			yield put(actions.openAddNewValuePopup(false));
		}
		yield put(actions.setIsLoading(false));
	} catch (e: any) {
		let errorMessage = t('something went wrong');
		try {
			const errors = JSON.parse(e.message);
			errorMessage = errors.message;
		} catch (parseError) {
			console.error('Error parsing JSON:', parseError);
		}
		yield put(actions.setGatLanguageError(errorMessage));
		yield put(actions.setLanguageData(initialState.languageData));
		yield put(actions.setIsLoading(false));
		yield put(actions.openAddNewValuePopup(false));
	}
}

export function* TranslationsRepoSaga() {
	yield takeLatest(actions.getTranslation.type, getTranslation);
	yield takeLatest(actions.saveTranslation.type, saveTranslation);
	yield takeLatest(
		actions.addValueToTranslationData.type,
		addValueToTranslationData,
	);
}
