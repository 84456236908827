import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { IS_PRODUCTION } from 'utils/constants';

import en from './en/translation.json';
import he from './he/translation.json';
import { ConvertedToObjectType } from './types';
import { DEFAULT_LANGUAGE } from 'app/containers/Auth/slice';

const translationsJson = {
	en: {
		translation: en,
	},
	he: {
		translation: he,
	},
};

export type TranslationResource = typeof en;
export type LanguageKey = keyof TranslationResource;

export const translations: ConvertedToObjectType<TranslationResource> =
	{} as any;

/*
 * Converts the static JSON file into an object where keys are identical
 * but values are strings concatenated according to syntax.
 * This is helpful when using the JSON file keys and still have the intellisense support
 * along with type-safety
 */
const convertLanguageJsonToObject = (obj: any, dict: any, current?: string) => {
	Object.keys(obj).forEach(key => {
		const currentLookupKey = current ? `${current}.${key}` : key;
		if (typeof obj[key] === 'object') {
			dict[key] = {};
			convertLanguageJsonToObject(obj[key], dict[key], currentLookupKey);
		} else {
			dict[key] = currentLookupKey;
		}
	});
};

export const i18n = i18next
	.use(initReactI18next)
	.use(LanguageDetector)
	.init(
		{
			resources: translationsJson,
			fallbackLng: DEFAULT_LANGUAGE,
			lng: localStorage.getItem('i18nextLng') || DEFAULT_LANGUAGE,
			debug: IS_PRODUCTION && process.env.NODE_ENV !== 'test',

			interpolation: {
				escapeValue: false,
			},
		},
		() => {
			convertLanguageJsonToObject(en, translations);
		},
	);

export const changeLanguage = language => {
	localStorage.setItem('i18nextLng', language);
	i18next.changeLanguage(language);
};
