import React, { useState } from 'react';
import { Box, Slider, Tooltip, Button, ClickAwayListener } from '@mui/material';
import clsx from 'clsx';
import { useStyles } from './styles';
import SliderThumbComponent from '../SliderThumb';
import { ItemToll } from '.';
import theme from 'styles/theme/mui';
import { settingsIcon } from 'assets/images';
import { SwitchToFilter } from 'app/components/Switch';
import { t } from 'i18next';
import { useIsRtl } from 'styles/theme/utils';

type Props = {
	innerElement: any;
	index: number;
	disabled: boolean;
	innerIndex: number;
	nutrients: any;
	setNutrients: any;
	setVitaminMinerals: any;
	vitaminMinerals: any;
	nutrientAndVitaminDetails: any;
	allVitaminMinerals: any;
};

const InnerSlider = ({
	index,
	innerIndex,
	innerElement,
	disabled,
	nutrients,
	setNutrients,
	setVitaminMinerals,
	vitaminMinerals,
	nutrientAndVitaminDetails,
	allVitaminMinerals,
}: Props) => {
	const styles = useStyles();
	const [openTooltip, setOpenTooltip] = useState(false);
	const isRTL = useIsRtl();
	const styleThumb = isRTL
		? {
				top: 0.01,
				transform: 'translateX(50%)',
		  }
		: {
				transform: 'translateX(-50%) translateY(1)',
		  };
	const handleTooltipClose = () => {
		setOpenTooltip(false);
	};

	const handleTooltipOpen = () => {
		setOpenTooltip(true);
	};
	const handleInnerSliderChange = (index, innerIndex, value) => {
		const nutrientData = [...nutrients];
		const fatPerTypes = nutrientData[index].fatPerTypes;
		if (fatPerTypes) {
			fatPerTypes[innerIndex].selectedValue = {
				...fatPerTypes[innerIndex].selectedValue,
				value,
			};
			const nutrientIndex = nutrientData.findIndex(
				nutrient => fatPerTypes[innerIndex].optionId === nutrient.optionId,
			);
			nutrientData[nutrientIndex].selectedValue = {
				...nutrientData[nutrientIndex].selectedValue,
				value,
			};
			nutrientData[index] = { ...nutrientData[index], fatPerTypes };
			setNutrients([...nutrientData]);
		}
	};
	return (
		<Box className={clsx(styles.tooltipInnerBoxFlex)}>
			<Box className={clsx(styles.innerSlider)}>
				<Box component="div" className={clsx(styles.tooltipInnerBox)}>
					<p className={clsx(styles.titleButton)}>{innerElement.optionText}</p>
					<Box
						sx={{
							display: 'flex',
						}}
					>
						<ClickAwayListener onClickAway={handleTooltipClose}>
							<div className={clsx(styles.tooltipContainer)}>
								<Tooltip
									title={
										<ItemToll
											element={innerElement}
											index={index}
											_type={'child'}
											nutrients={nutrients}
											setNutrients={setNutrients}
											setVitaminMinerals={setVitaminMinerals}
											vitaminMinerals={vitaminMinerals}
										/>
									}
									arrow
									placement="top-end"
									leaveDelay={200}
									PopperProps={{
										disablePortal: true,
									}}
									onClose={handleTooltipClose}
									open={openTooltip}
									disableFocusListener
									disableHoverListener
									disableTouchListener
								>
									<Button
										onClick={() => {
											handleTooltipOpen();
										}}
										sx={{ borderRadius: '50px', height: 25, minWidth: 16 }}
									>
										<img
											src={settingsIcon}
											alt={t('edit')}
											width="20"
											height="20"
										/>
									</Button>
								</Tooltip>
							</div>
						</ClickAwayListener>
					</Box>
				</Box>
				<Box className={clsx(styles.innerSliderBox)}>
					<Slider
						size="small"
						components={{ Thumb: SliderThumbComponent }}
						getAriaLabel={() => 'Minimum distance'}
						marks={innerElement.sliderValues}
						step={1}
						min={0}
						max={2}
						disabled={disabled}
						sx={{
							'& .MuiSlider-thumb': {
								...styleThumb,
								background: disabled
									? theme.palette.grey[200]
									: innerElement.selectedValue?.value !== 1
									? theme.palette.secondary.main
									: theme.palette.primary.main,
							},
						}}
						value={innerElement.selectedValue?.value}
						valueLabelDisplay="off"
						className={clsx(styles.sliderMark)}
						onChange={event =>
							handleInnerSliderChange(
								index,
								innerIndex,
								Number((event.target as HTMLInputElement).value),
							)
						}
					/>
				</Box>
				{innerElement.selectedValue.value !== 1 && (
					<Box className={clsx(styles.switchBox)}>
						<SwitchToFilter
							element={innerElement}
							setNutrients={setNutrients}
							nutrients={nutrients}
							setVitaminMinerals={setVitaminMinerals}
							nutrientAndVitaminDetails={nutrientAndVitaminDetails}
							allVitaminMinerals={allVitaminMinerals}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default InnerSlider;
