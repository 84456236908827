import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
	Box,
	Button,
	ClickAwayListener,
	FormControl,
	MenuItem,
	Tooltip,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useInjectReducer } from 'utils/redux-injectors';
import { reducer, sliceKey } from '../../../redux/slice';
import { ItemToll } from '../NutrientSlider';
import { useStyles } from './styles';
import { settingsIcon } from 'assets/images';
import { SwitchToFilter } from 'app/components/Switch';
import { t } from 'i18next';
import { useIsRtl } from 'styles/theme/utils';

const OptionMenu = ({
	element,
	index,
	nutrients,
	setNutrients,
	setVitaminMinerals,
	vitaminMinerals,
	nutrientAndVitaminDetails,
	allVitaminMinerals,
	setUserVitamins,
	userVitamins,
}) => {
	useInjectReducer({
		key: sliceKey,
		reducer: reducer,
	});
	const isRTL = useIsRtl();
	const onMenuChange = (optionId, value) => {
		const vitaminMineralsData = [...allVitaminMinerals];
		const index = vitaminMineralsData.findIndex(
			vitamin => vitamin.optionId === optionId,
		);
		const { selectedValue } = vitaminMineralsData[index];
		vitaminMineralsData[index].selectedValue.value = value;
		const vitaminMineralsValue = {
			optionId,
			selectedValue,
		};
		setVitaminMinerals([...vitaminMineralsData]);
		setUserVitamins([...userVitamins, vitaminMineralsValue]);
	};
	const handleChange = (event: SelectChangeEvent, optionId) => {
		onMenuChange(optionId, event.target.value);
	};
	const [openTooltip, setOpenTooltip] = useState(false);
	const handleTooltipClose = () => {
		setOpenTooltip(false);
	};
	const handleTooltipOpen = () => {
		setOpenTooltip(true);
	};

	const styles = useStyles();
	return (
		<Box className={clsx(styles.box)}>
			<Box component="div" className={clsx(styles.tooltipBox)}>
				<p className={clsx(styles.button)}>{element.optionText}</p>
				<Box
					sx={{
						display: 'flex',
					}}
				>
					<ClickAwayListener onClickAway={handleTooltipClose}>
						<div className={clsx(styles.tooltipContainer)}>
							<Tooltip
								title={
									<ItemToll
										element={element}
										index={index}
										_type={'father'}
										nutrients={nutrients}
										setNutrients={setNutrients}
										setVitaminMinerals={setVitaminMinerals}
										vitaminMinerals={vitaminMinerals}
									/>
								}
								arrow
								placement="top-end"
								leaveDelay={200}
								PopperProps={{
									disablePortal: true,
								}}
								onClose={handleTooltipClose}
								open={openTooltip}
								disableFocusListener
								disableHoverListener
								disableTouchListener
							>
								<Button
									onClick={() => {
										handleTooltipOpen();
									}}
									sx={{ borderRadius: '50px', height: 25, minWidth: 16 }}
								>
									<img
										src={settingsIcon}
										alt={t('edit')}
										width="20"
										height="20"
									/>
								</Button>
							</Tooltip>
						</div>
					</ClickAwayListener>
				</Box>
			</Box>
			<FormControl
				sx={{ m: 1, margin: '0', width: '100%', marginBottom: '10px' }}
			>
				<span
					className={
						element.selectedValue.value === 1
							? clsx(styles.circle)
							: clsx(styles.circleSelected)
					}
				></span>
				<Select
					value={element.selectedValue.value}
					IconComponent={KeyboardArrowDownIcon}
					onChange={e => handleChange(e, element.optionId)}
					displayEmpty
					inputProps={{ 'aria-label': 'Without label' }}
					sx={
						isRTL
							? {
									'& .MuiSelect-icon': {
										right: 'auto',
										left: 8,
									},
							  }
							: {}
					}
					className={
						element.selectedValue.value === 1
							? clsx(styles.root)
							: clsx(styles.rootSelected)
					}
				>
					{element.sliderValues.map(menu => (
						<MenuItem value={menu.value} key={menu.value}>
							{menu.text}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{element.selectedValue.value !== 1 && (
				<Box className={clsx(styles.switchToFilterBox)}>
					<SwitchToFilter
						element={element}
						setNutrients={setNutrients}
						nutrients={nutrients}
						setVitaminMinerals={setVitaminMinerals}
						nutrientAndVitaminDetails={nutrientAndVitaminDetails}
						allVitaminMinerals={allVitaminMinerals}
					/>
				</Box>
			)}
		</Box>
	);
};

export default OptionMenu;
