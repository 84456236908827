import { changeLanguage, LanguageKey } from 'locales/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { delay } from 'redux-saga/effects';
import { actions } from '../Auth/slice';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';

interface Props {
	className?: string | {} | any;
}

export function LanguageSwitch({ className }: Props) {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const handleLanguageChange = (event: React.ChangeEvent<any>) => {
		const language = event.target.value as LanguageKey;
		dispatch(actions.setLanguage(language));
		changeLanguage(language);
	};

	const isLoading = false;

	return (
		<Wrapper>
			{isLoading ? (
				<CircularProgress color="inherit" size={16} />
			) : (
				<>
					<select
						value={i18next.language}
						onChange={handleLanguageChange}
						className={className ? className : ''}
						style={{ maxWidth: 60 }}
					>
						<option value="en">EN</option>
						<option value="he">HE</option>
					</select>
				</>
			)}
		</Wrapper>
	);
}

const Wrapper = styled('div')({
	display: 'flex',
	flexDirection: 'row',
});
