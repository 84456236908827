import { createSlice } from 'utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { InitialTranslationsState } from '../types';

export const initialState: InitialTranslationsState = {
	error: '',
	languageData: { language: '', translationData: [] },
	isLoading: false,
	openAddNewValuePopup: false,
	formAddNewValue: {},
};

export const useTranslationsSlice = createSlice({
	name: 'translationsState',
	initialState,
	reducers: {
		getTranslation: (state, action: PayloadAction<any>) => {},
		addValueToTranslationData: (state, action: PayloadAction<any>) => {},
		setGatLanguageError: (state, action: PayloadAction<any>) => {
			state.error = action.payload;
		},
		setLanguageData: (state, action: PayloadAction<any>) => {
			state.languageData = action?.payload;
		},
		setIsLoading: (state, action: PayloadAction<any>) => {
			state.isLoading = action?.payload;
		},
		saveTranslation: (state, action: PayloadAction<any>) => {},
		openAddNewValuePopup: (state, action: PayloadAction<any>) => {
			state.openAddNewValuePopup = action?.payload;
		},
		updateFormAddNewValue: (
			state,
			action: PayloadAction<{ key: string; value: any }>,
		) => {
			state.formAddNewValue[action.payload.key] = action.payload.value;
		},
	},
});

export const { reducer, actions, name: sliceKey } = useTranslationsSlice;
