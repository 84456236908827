import {
	Box,
	Button,
	ButtonGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { IconEdit, IconTrash } from 'assets/images';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFaqList } from '../../redux/selector';
import { useStyles } from '../../style';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { actions } from '../../redux/slice';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common,
		color: '#645B70',
		fontWeight: '400',
		background: 'transparent',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		fontWeight: '500',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:hover': {
		backgroundColor: '#ece6f4',
		cursor: 'pointer',
		'& .MuiTableCell-root': {
			color: theme.palette.primary.main,
		},
	},
}));

const TopicsListTable = ({
	setDeleteTemplatePopupOpen,
	setEditTemplatePopupOpen,
	orderCallback,
}) => {
	const styles = useStyles();
	const dispatch = useDispatch();
	const useFaqList = useSelector(selectFaqList);

	const moveRow = (order, typeAction) => {
		if (
			(order === 0 && typeAction === '-') ||
			(order === useFaqList.length - 1 && typeAction === '+')
		) {
			return;
		}
		dispatch(
			actions.setOrder({
				order,
				typeAction,
				orderCallback: orderCallback,
			}),
		);
	};

	return (
		<>
			<Table
				sx={{ width: 700 }}
				aria-label="simple table"
				style={{ width: '100%', overflowX: 'auto' }}
			>
				<TableHead>
					<TableRow>
						<StyledTableCell style={{ paddingLeft: '15px', width: '5%' }}>
							{t('Order')}
						</StyledTableCell>
						<StyledTableCell style={{ width: '10%' }}>
							{t('Topic')}
						</StyledTableCell>
						<StyledTableCell
							style={{ width: '20%' }}
							className={clsx([styles.hideOnSmallScreen])}
						>
							{t('Question')}
						</StyledTableCell>
						<StyledTableCell
							style={{ width: '40%' }}
							className={clsx(styles.hideOnSmallScreen)}
						>
							{t('Answer')}
						</StyledTableCell>
						<StyledTableCell align="center">{t('Actions')}</StyledTableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{useFaqList?.map((faq, index) => {
						return (
							<StyledTableRow
								key={index}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<StyledTableCell
									style={{
										paddingLeft: '15px',
									}}
									component="th"
									scope="row"
								>
									{faq.order}
								</StyledTableCell>
								<StyledTableCell className={clsx(styles.hideOnSmallScreen)}>
									{faq.topic}
								</StyledTableCell>
								<StyledTableCell className={clsx(styles.hideOnSmallScreen)}>
									{faq.question}
								</StyledTableCell>
								<StyledTableCell className={clsx(styles.hideOnSmallScreen)}>
									{faq.answer}
								</StyledTableCell>
								<StyledTableCell sx={{ width: '15%', textAlign: 'center' }}>
									<ButtonGroup
										className="iconBtnGroup"
										variant="text"
										aria-label="text button group"
										sx={{ justifyContent: 'flex-end' }}
									>
										<Button
											onClick={() => moveRow(faq?.order, '-')}
											disabled={faq?.order === 0}
										>
											<Tooltip title={t('Move Up')} placement="top">
												<ArrowUpwardIcon />
											</Tooltip>
										</Button>
										<Button
											onClick={() => moveRow(faq?.order, '+')}
											disabled={faq?.order === useFaqList.length - 1}
										>
											<Tooltip title={t('Move Down')} placement="top">
												<ArrowDownwardIcon />
											</Tooltip>
										</Button>
										<Button onClick={() => setEditTemplatePopupOpen(faq)}>
											<Tooltip title={t('Edit')} placement="top">
												<img src={IconEdit} alt={t('edit')} />
											</Tooltip>
										</Button>
										<Button
											onClick={() => {
												setDeleteTemplatePopupOpen(faq);
											}}
										>
											<Tooltip title={t('Trash')} placement="top">
												<img src={IconTrash} alt={t('trash')} />
											</Tooltip>
										</Button>
									</ButtonGroup>
								</StyledTableCell>
							</StyledTableRow>
						);
					})}
				</TableBody>
			</Table>
			{useFaqList?.length === 0 && (
				<Box
					style={{
						height: '45vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<p>{t('No records found')}</p>
				</Box>
			)}
		</>
	);
};

export default TopicsListTable;
