import {
	Box,
	InputLabel,
	ListSubheader,
	MenuItem,
	Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyles } from './style';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectPractitionerRole,
	selectPractitionerTemplates,
} from 'app/containers/Auth/selectors';
import { actions as templatesActions } from '../../Templates/redux/slice';
import { actions as patientActions } from '../../Patients/redux/slice';
import { PatientDetail } from 'app/containers/Patients/types';
import {
	selectAnydishTemplatesData,
	selectClinicTemplatesData,
	selectPrivateTemplatesData,
	selectGetTemplateData,
} from '../redux/selectors';
import CustomModal from 'app/components/CustomModal';
import { IconBluePlus } from 'assets/images';
import { Roles } from 'app/containers/Practitioner/PractitionerTable';
import { t } from 'i18next';

export function SelectTemplate({ patientDetail }) {
	const usePractitionerTemplates = useSelector(selectPractitionerTemplates);
	const useGetTemplateData = useSelector(selectGetTemplateData);
	const useAnydishTemplatesData = useSelector(selectAnydishTemplatesData);
	const usePrivateTemplatesData = useSelector(selectPrivateTemplatesData);
	const useClinicTemplatesData = useSelector(selectClinicTemplatesData);
	const initValue = '';
	const [selectedValue, setSelectedValue] = useState(initValue);
	const [selectedTemplateData, setSelectedTemplateData] = useState<any>(null);
	const [updateUser, setUpdateUser] = useState(false);
	const [isOpenWarningPopup, setIsOpenWarningPopup] = useState(false);
	const styles = useStyles();
	const dispatch = useDispatch();
	const usePractitionerRole = useSelector(selectPractitionerRole);

	useEffect(() => {
		if (updateUser) {
			const _patientDetail: PatientDetail = JSON.parse(
				JSON.stringify(patientDetail),
			);
			_patientDetail.practitionerPreferences =
				useGetTemplateData?.templateData?.practitionerPreferences;
			_patientDetail.dieteryPreferences.restriction_array =
				useGetTemplateData?.templateData?.dieteryPreferences?.restriction_array;

			dispatch(patientActions.updatePatient(_patientDetail));
			setUpdateUser(false);
		}
	}, [
		dispatch,
		patientDetail,
		updateUser,
		useGetTemplateData?.templateData?.dieteryPreferences?.restriction_array,
		useGetTemplateData?.templateData?.practitionerPreferences,
	]);
	const gatTemplateCallback = () => {
		setUpdateUser(true);
		dispatch(patientActions.openAllVitamins(true));
	};
	const handleClose = e => {
		setIsOpenWarningPopup(e);
		setSelectedValue(initValue);
		setSelectedTemplateData(null);
	};
	const findTemplateData = templateId => {
		let templateData;
		[
			...useAnydishTemplatesData,
			...useClinicTemplatesData,
			...usePrivateTemplatesData,
		].forEach(template => {
			if (template._id === templateId) {
				templateData = template;
			}
		});
		setSelectedTemplateData(templateData);
	};
	const handleChange = e => {
		const ev = e.target.value;
		if (ev) {
			setIsOpenWarningPopup(true);
			setSelectedValue(ev);
			findTemplateData(ev);
		}
	};
	const confirmImportTemplate = () => {
		const allTemplates = [
			...useAnydishTemplatesData,
			...useClinicTemplatesData,
			...usePrivateTemplatesData,
		];
		let template = null;
		allTemplates.forEach(temp => {
			if (temp._id == selectedValue) {
				template = temp;
			}
		});
		setSelectedValue(initValue);
		dispatch(templatesActions.getTemplateData(template));
		gatTemplateCallback();
		setIsOpenWarningPopup(false);
	};

	const LabelDivDescriptions = ({ templateName, templateData, title }) => {
		return (
			<div className={styles.labelDiv}>
				<InputLabel className={styles.label}>
					{templateName} - {title}
				</InputLabel>
				<span key={templateData}>
					{templateData}
					<br />
				</span>
			</div>
		);
	};

	const LabelDivReferences = ({ templateName, templateData, title }) => {
		return (
			<div className={styles.labelDiv}>
				<InputLabel className={styles.label}>
					{templateName} - {title}
				</InputLabel>
				{templateData?.map((data, index) => (
					<p
						style={{
							marginTop: 0,
							margin: 0,
							display: 'flex',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
					>
						<p
							style={{
								marginTop: 0,
								margin: 0,
								marginRight: '10px',
							}}
						>
							{index + 1}.
						</p>
						<a
							href={data}
							target="_blank"
							rel="noopener noreferrer"
							style={{
								marginTop: 0,
								margin: 0,
								width: '95%',
								color: '#1976d2',
								textDecoration: 'underline',
							}}
						>
							{`${data}`}
						</a>
					</p>
				))}
			</div>
		);
	};

	const renderValue = value => {
		if (!value) {
			return (
				<span className={clsx(styles.selectText)}>{t('Apply a protocol')}</span>
			);
		}
		const selectedTemplate = [
			...(usePrivateTemplatesData || []),
			...(useClinicTemplatesData || []),
			...(useAnydishTemplatesData || []),
		].find(template => template?._id === value);
		return (
			<span className={clsx(styles.selectText)}>
				{selectedTemplate ? selectedTemplate.templateName : ''}
			</span>
		);
	};

	return (
		<Box className={clsx(styles.root)}>
			{useAnydishTemplatesData?.length ||
			useClinicTemplatesData?.length ||
			usePrivateTemplatesData?.length ? (
				<Select
					defaultValue={initValue}
					value={selectedValue}
					IconComponent={KeyboardArrowDownIcon}
					onChange={e => handleChange(e)}
					displayEmpty
					inputProps={{ 'aria-label': 'Without label' }}
					className={clsx(styles.select)}
					renderValue={renderValue}
				>
					<MenuItem value="">
						<p className={clsx(styles.selectText)}>{t('Apply a protocol')}</p>
					</MenuItem>
					{(usePractitionerRole !== Roles.CLINIC_TEMPLATE ||
						usePractitionerRole !== Roles.CLINIC_ADMIN) &&
						usePrivateTemplatesData?.length && (
							<ListSubheader className={clsx(styles.listSubheader)}>
								{t('My protocols')}
							</ListSubheader>
						)}
					{(usePractitionerRole !== Roles.CLINIC_TEMPLATE ||
						usePractitionerRole !== Roles.CLINIC_ADMIN) &&
						usePrivateTemplatesData?.length &&
						usePrivateTemplatesData?.map((template: any) => (
							<MenuItem value={template?._id} key={template?._id}>
								<p className={clsx(styles.selectText)}>
									{template?.templateName}
								</p>
							</MenuItem>
						))}
					{useClinicTemplatesData?.length && (
						<ListSubheader className={clsx(styles.listSubheader)}>
							{t('Clinic protocols')}
						</ListSubheader>
					)}
					{useClinicTemplatesData?.length &&
						useClinicTemplatesData?.map((template: any) => (
							<MenuItem value={template?._id} key={template?._id}>
								<p className={clsx(styles.selectText)}>
									{template?.templateName}
								</p>
							</MenuItem>
						))}
					{useAnydishTemplatesData?.length && (
						<ListSubheader className={clsx(styles.listSubheader)}>
							{t('anydish protocols')}
						</ListSubheader>
					)}
					{useAnydishTemplatesData?.length &&
						useAnydishTemplatesData?.map((template: any) => (
							<MenuItem value={template?._id} key={template?._id}>
								<p className={clsx(styles.selectText)}>
									{template?.templateName}
								</p>
							</MenuItem>
						))}
				</Select>
			) : null}
			<CustomModal
				btnTxt={t('Apply')}
				openModal={isOpenWarningPopup}
				setOpen={handleClose}
				Icon={IconBluePlus}
				heading={t('Pay attention')}
				secondHeading={t(
					'Please note that applying a template to a user profile will override the existing profile',
				)}
				modalClass={clsx(styles.modalClass)}
				onSubmit={() => {
					confirmImportTemplate();
				}}
				showChildrenFirst={true}
			>
				{(selectedTemplateData?.templateData?.templateReferences?.length ||
					selectedTemplateData?.templateData?.templateDescriptions) && (
					<div className={styles.referencesContainer}>
						{selectedTemplateData?.templateData?.templateDescriptions && (
							<LabelDivDescriptions
								templateName={selectedTemplateData?.templateName}
								templateData={
									selectedTemplateData?.templateData?.templateDescriptions
								}
								title={t('Description')}
							/>
						)}
						{selectedTemplateData?.templateData?.templateReferences?.length >
							0 && (
							<LabelDivReferences
								templateName={selectedTemplateData?.templateName}
								templateData={
									selectedTemplateData?.templateData?.templateReferences
								}
								title={t('References')}
							/>
						)}
					</div>
				)}
			</CustomModal>
		</Box>
	);
}
