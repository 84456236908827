import { TextField } from 'app/components/TextField';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Mail } from '@mui/icons-material';
import { selectForgotForm } from '../selectors';
import { actions } from '../slice';
import { useStyles } from './styles';
import { IconLogo } from 'assets/images';
import { ImageForgetPassword } from 'assets/images';
import {
	Box,
	Container,
	Alert,
	Typography,
	Button,
	FormLabel,
	FormControl,
	AlertTitle,
} from '@mui/material';

import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
const ForgotPassword = () => {
	const styles = useStyles();
	const [isSubmitted, setSubmitted] = useState<boolean>(false);
	const form = useSelector(selectForgotForm);
	const { email, error } = form;
	const dispatch = useDispatch();
	const [errorMsg, setErrorMsg] = useState('');
	const { t } = useTranslation();

	const onSuccess = () => {
		setSubmitted(true);
		dispatch(actions.resetError());
		dispatch(actions.resetEmail());
	};
	const handleSubmit = e => {
		e.preventDefault();
		dispatch(actions.forgotPassword({ callback: onSuccess }));
	};
	const handleEmailChange = e => {
		const { value } = e.target;
		if (error) {
			dispatch(actions.resetError());
		}
		dispatch(actions.setForgotEmail(value));
	};
	const navigate = useNavigate();
	const GoToLogin = () => {
		navigate('/');
	};
	const forgotSubmitContent = (
		<>
			<Box className={styles.form}>
				<Box className={styles.successHeading}>{t('Reset Password')}</Box>
				<Mail className={styles.iconMail} />
				<Box className={styles.mailSent}>
					{t('An email has been sent to you to reset your password')}.
				</Box>
				<div className={styles.descText}>
					{t('Click on the reset link in the email to update your password')}.
				</div>
				<FormControl sx={{ width: '20%' }} variant="outlined">
					<Box component={`div`} className={clsx([styles.btnBox])}>
						<Button type="button" variant="contained" onClick={GoToLogin}>
							{t('Go To Login')}
						</Button>
					</Box>
				</FormControl>
			</Box>
		</>
	);

	useEffect(() => {
		console.log(error, 'error message here');
		if (error) setErrorMsg(t(`${error}`));
		// eslint-disable-next-line
	}, [error]);

	const forgotForm = (
		<Box display="flex" alignItems={'center'} className={clsx(styles.root)}>
			<Container className={clsx(styles.container)}>
				<Box>
					<img
						src={ImageForgetPassword}
						alt="right bg"
						className={clsx(styles.img)}
					/>
					<Box component={`div`} className={clsx(styles.content)}>
						<Box component={`div`} className={clsx(styles.head)}>
							<img src={IconLogo} alt="logo" className={clsx(styles.logo)} />
							<Typography variant={`h6`} className={clsx(styles.topTxt)}>
								{t(
									'Enter the email address associated with your account, and we’ll send you a link to reset your password',
								)}{' '}
							</Typography>
						</Box>

						<Box
							component="form"
							noValidate
							sx={{
								display: 'grid',
								gridTemplateColumns: { sm: '1fr' },
								gap: 1,
								columnGap: 7,
							}}
							className={clsx(styles.loginForm)}
						>
							{errorMsg && (
								<Alert className={styles.error} severity="error">
									<AlertTitle sx={{ padding: '0 10px' }}>{errorMsg}</AlertTitle>
								</Alert>
							)}
							<FormControl variant="outlined">
								<Box component={`div`} className={clsx([styles.formGroup])}>
									<FormLabel className={clsx([styles.title])}>
										{t('Email address')}
									</FormLabel>
								</Box>
								<TextField
									variant="outlined"
									required
									id="email"
									type="email"
									placeholder="JennyS@gmail.com"
									name="Email address"
									autoComplete="email"
									autoFocus
									value={email}
									onChange={handleEmailChange}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}
								/>
							</FormControl>
							<FormControl sx={{ width: '100' }} variant="outlined">
								<Box component={`div`} className={clsx([styles.btnBox])}>
									<Button
										type="button"
										variant="contained"
										onClick={handleSubmit}
									>
										{t('Continue')}
									</Button>
								</Box>
							</FormControl>
						</Box>
					</Box>
				</Box>
			</Container>
		</Box>
	);

	return !isSubmitted ? forgotForm : forgotSubmitContent;
};
export default ForgotPassword;
