import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useStyles } from './styles';
import { updateFarPerType } from 'app/containers/Patients/PatientDetails/utils';
import { t } from 'i18next';
import { useIsRtl } from 'styles/theme/utils';

type Props = {
	element?: any;
	setNutrients: any;
	nutrients: any;
	setVitaminMinerals: any;
	nutrientAndVitaminDetails: any;
	allVitaminMinerals: any;
};

export const SwitchToFilter = ({
	element,
	setNutrients,
	nutrients,
	setVitaminMinerals,
	nutrientAndVitaminDetails,
	allVitaminMinerals,
}: Props) => {
	const isRTL = useIsRtl();
	const classes = useStyles();
	const PREFERRED = t('Preferred');
	const REQUIRED = t('Required');
	const [checked, setChecked] = useState(element?.selectedValue?.toFilter);
	const [switchText, setSwitchText] = useState(
		element?.selectedValue?.toFilter ? REQUIRED : PREFERRED,
	);
	useEffect(() => {
		setChecked(element?.selectedValue?.toFilter);
		setSwitchText(element?.selectedValue?.toFilter ? REQUIRED : PREFERRED);
	}, [element]);
	const HEIGHT = 28;
	const WIDTH = isRTL ? 80 : 110;
	const BORDER_SIZE = 2;
	const handleToFilter = element => {
		for (const item of nutrientAndVitaminDetails) {
			const defaultObject = item.results.find(
				nut => nut.optionId === element.optionId,
			);
			if (defaultObject) {
				const nutrientType = item.label;
				if (nutrientType === 'Nutrients') {
					const nutrientData = [...nutrients];
					const index = nutrientData.findIndex(
						nutrient => nutrient.optionId === element.optionId,
					);
					nutrientData[index].selectedValue.toFilter =
						!nutrientData[index].selectedValue?.toFilter;
					const _nutrientData = updateFarPerType(nutrientData);
					setNutrients([..._nutrientData]);
				} else {
					const vitaminMineralsData = [...allVitaminMinerals];
					const index = vitaminMineralsData.findIndex(
						nutrient => nutrient.optionId === element.optionId,
					);
					vitaminMineralsData[index].selectedValue.toFilter =
						!vitaminMineralsData[index].selectedValue?.toFilter;
					setVitaminMinerals([...vitaminMineralsData]);
				}
			}
		}
	};
	const onClick = () => {
		setChecked(!checked);
		checked ? setSwitchText(PREFERRED) : setSwitchText(REQUIRED);
		handleToFilter(element);
	};
	return (
		<Button
			sx={{
				width: WIDTH,
				backgroundColor: !checked ? '#C3C0C8' : '#EBE6F3',
				borderRadius: 50,
				height: HEIGHT,
				padding: '2px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				color: !checked ? '#fff' : '#40018C',
				fontSize: '14px',
				'&:hover': {
					backgroundColor: !checked ? '#C3C0C8' : '#EBE6F3',
					opacity: 0.9,
				},
			}}
			disableElevation
			disableRipple={true}
			disableFocusRipple={true}
			onClick={() => onClick()}
		>
			<Box
				sx={{
					backgroundColor: !checked ? '#fff' : '#40018C',
					borderRadius: 50,
					height: HEIGHT - BORDER_SIZE,
					width: HEIGHT - BORDER_SIZE,
					borderColor: !checked ? '#C3C0C8' : '#EBE6F3',
					borderWidth: BORDER_SIZE,
					transition: 'transform 0.3s ease',
					transform: checked
						? `translateX(${isRTL ? '-' : '+'}${WIDTH - HEIGHT}px)`
						: 'translateX(0)',
				}}
			/>
			<Box
				sx={{
					borderRadius: 50,
					height: HEIGHT,
					display: 'flex',
					alignItems: 'center',
					padding: '0px 7px',
					transition: 'transform 0.3s ease',
					transform: checked
						? `translateX(${isRTL ? '+' : '-'}${HEIGHT}px)`
						: 'translateX(0)',
				}}
			>
				<p>{switchText}</p>
			</Box>
		</Button>
	);
};
