import {
	Box,
	Button,
	ButtonGroup,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import {
	selectPractitionerTemplates,
	selectUserType,
} from 'app/containers/Auth/selectors';
import clsx from 'clsx';
import { DateTimeFormatOptions } from 'luxon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { duplicate, IconEdit, IconTrash } from '../../../../assets/images';
import { useStyles } from './style';
import { actions } from '../redux/slice';
import { selectTemplatesList } from '../redux/selectors';
import { t } from 'i18next';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common,
		color: '#645B70',
		fontWeight: '400',
		background: 'transparent',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
		fontWeight: '500',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:hover': {
		backgroundColor: '#ece6f4',
		cursor: 'pointer',
		'& .MuiTableCell-root': {
			color: theme.palette.primary.main,
		},
	},
}));

const TemplateListTable = ({
	setDeleteTemplatePopupOpen,
	setEditTemplatePopupOpen,
	setDuplicateTemplateCallback,
	templateType,
}) => {
	const styles = useStyles();
	const navigate = useNavigate();
	const userType = useSelector(selectUserType);
	const dispatch = useDispatch();
	const usePractitionerTemplates: any[] = useSelector(selectTemplatesList);

	//   ===============================handlechange=====================
	const navigateToTemplateDetails = template => {
		dispatch(actions.setSelectedTemplate(template));
		const state = {
			templateType,
		};
		navigate(`/templates/${template?._id}`, { state });
	};
	const handleDuplicate = template => {
		dispatch(
			actions.setDuplicateTemplate({
				template: template,
				callback: setDuplicateTemplateCallback,
			}),
		);
	};
	return (
		<>
			<Table
				sx={{ width: 700 }}
				aria-label="simple table"
				style={{ width: '100%', overflowX: 'auto' }}
			>
				<TableHead>
					<TableRow>
						<StyledTableCell style={{ paddingLeft: '15px', width: '33%' }}>
							{t('Protocol name')}
						</StyledTableCell>
						<StyledTableCell
							style={{ width: '33%' }}
							align="left"
							className={clsx(styles.hideOnSmallScreen)}
						>
							{t('Last Update')}
						</StyledTableCell>
						<StyledTableCell align="center">{t('Actions')}</StyledTableCell>
					</TableRow>
				</TableHead>

				<TableBody>
					{usePractitionerTemplates?.map((template, index) => {
						let formattedDate = '';
						if (template?.templateData?.lastPlan) {
							const dateObj = new Date(template?.templateData?.lastPlan);
							const options: DateTimeFormatOptions = {
								day: '2-digit',
								month: '2-digit',
								year: 'numeric',
							};
							formattedDate = dateObj.toLocaleDateString('en-GB', options);
						}

						return (
							<StyledTableRow
								key={index}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<StyledTableCell
									style={{ paddingLeft: '15px' }}
									component="th"
									scope="row"
									onClick={() => navigateToTemplateDetails(template)}
								>
									{template.templateName}
								</StyledTableCell>
								<StyledTableCell
									className={clsx(styles.hideOnSmallScreen)}
									onClick={() => navigateToTemplateDetails(template)}
								>
									{formattedDate}
								</StyledTableCell>
								<StyledTableCell
									align="left"
									sx={{ width: '15%', textAlign: 'center' }}
								>
									<ButtonGroup
										className="iconBtnGroup"
										variant="text"
										aria-label="text button group"
										sx={{ justifyContent: 'flex-end' }}
									>
										<Button onClick={() => setEditTemplatePopupOpen(template)}>
											<Tooltip title={t('Edit')} placement="top">
												<img src={IconEdit} alt={t('edit')} />
											</Tooltip>
										</Button>
										<Button onClick={() => handleDuplicate(template)}>
											<Tooltip title={t('Duplicate')} placement="top">
												<img src={duplicate} alt={t('duplicate')} />
											</Tooltip>
										</Button>
										<Button
											onClick={() => {
												setDeleteTemplatePopupOpen(template);
											}}
										>
											<Tooltip title={t('Trash')} placement="top">
												<img src={IconTrash} alt={t('trash')} />
											</Tooltip>
										</Button>
									</ButtonGroup>
								</StyledTableCell>
							</StyledTableRow>
						);
					})}
				</TableBody>
			</Table>
			{/* {usePractitionerTemplates && usePractitionerTemplates.length > 0 && (
				<Grid container position={'absolute'} bottom={0}>
					<Grid item xs={12} md={12}>
						<Box component={`div`} className={clsx(styles.pagination)}>
							<Stack mr={7}>
								<Pagination count={1} onChange={handlePaginationChange} />
							</Stack>
						</Box>
					</Grid>
				</Grid>
			)} */}
			{usePractitionerTemplates?.length === 0 && (
				<Box
					style={{
						height: '45vh',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<p>{t('No records found')}</p>
				</Box>
			)}
		</>
	);
};

export default TemplateListTable;
