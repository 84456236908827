import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
import {
	Box,
	Paper,
	ListItemText,
	ListItem,
	List,
	IconButton,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserSidebarOpen } from '../../containers/Patients/redux/selector';
import { selectLoginForm } from '../../containers/Auth/selectors';
import { actions } from '../../containers/Patients/redux/slice';
import { actions as authActions } from '../../containers/Auth/slice';
import { basicDomain, REACT_APP_MODE } from 'utils/constants';
import { LanguageSwitch } from 'app/containers/LanguageSwitch';
import { t } from 'i18next';
export const UserRightBar = () => {
	const userData = useSelector(selectLoginForm);
	const styles = useStyles();
	const isUserSidebarOpen = useSelector(selectUserSidebarOpen);

	const dispatch = useDispatch();

	const _handleLogout = () => {
		dispatch(authActions.logout());
		dispatch(actions.UserToggleMenu());
		localStorage.clear();
		window.location.href = basicDomain || '/';
	};

	return (
		<Box
			className={clsx(styles.sidebar, {
				[styles.activeSidebar]: isUserSidebarOpen,
			})}
		>
			<IconButton
				className={clsx(styles.headBtn)}
				onClick={() => dispatch(actions.UserToggleMenu())}
			>
				<CloseIcon />
			</IconButton>
			<Paper className={clsx(styles.innerSlide)}>
				<Box className={clsx(styles.head)}>
					<Typography variant={`h3`} className={clsx(styles.heading)}>
						{t('Account')}
					</Typography>
					<Box className={clsx(styles.userBox)}>
						<Box className={clsx(styles.userTxt)}>
							<Typography variant={`h5`} className={clsx(styles.boxHeading)}>
								{userData.name}
							</Typography>
							<Typography variant={`body1`} className={clsx(styles.desc)}>
								{userData.email}
							</Typography>
						</Box>
					</Box>
				</Box>

				<Box className={clsx(styles.inner)}>
					<List>
						<ListItem
							button
							component="a"
							href="https://www.anydish.me/about-1#faq"
							className={clsx(styles.link)}
						>
							<ListItemText primary={t('FAQ')} />
						</ListItem>
						<ListItem
							button
							component="a"
							href="https://www.anydish.me/about-1"
							className={clsx(styles.link)}
						>
							<ListItemText primary={t('Contact us')} />
						</ListItem>
						<ListItem
							button
							component="a"
							href="https://www.anydish.me/terms"
							className={clsx(styles.link)}
						>
							<ListItemText primary={t('Terms & Conditions')} />
						</ListItem>
						<ListItem button className={clsx(styles.link, styles.linkRed)}>
							<ListItemText
								primary={t('Sign Out')}
								onClick={() => {
									_handleLogout();
								}}
							/>
						</ListItem>
						{REACT_APP_MODE !== 'production' && (
							<ListItem button className={clsx(styles.link, styles.linkRed)}>
								<LanguageSwitch />
							</ListItem>
						)}
					</List>
				</Box>
			</Paper>
		</Box>
	);
};

export default UserRightBar;
