import { Backdrop, Box, CircularProgress } from '@mui/material';
import React from 'react';

const Loader = ({ opacity = false }: any) => {
	return (
		<div>
			<Backdrop
				sx={{
					color: '#40018C',
					zIndex: '100000',
					backgroundColor: `rgba(249, 249, 249, ${opacity})`,
				}}
				open={true}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box
				sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
			>
				<CircularProgress style={{ color: '#40018C' }} />
			</Box>
		</div>
	);
};

export default Loader;
