import { countryToLanguageMap } from './countryToLanguageMap';

export const RTL_LANGUAGES = [
	countryToLanguageMap.IL,
	// Add in future: 'fa', 'ur','ar'
];

export const isCurrentLanguageRTL = useCurrentLanguage => {
	return RTL_LANGUAGES.includes(useCurrentLanguage);
};
