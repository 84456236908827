import { Box } from '@mui/material';
import React from 'react';
import BoldText from '../BoldText';
import { t } from 'i18next';

const ExplanatoryNote = () => {
	return (
		<Box
			style={{
				width: '98%',
				padding: '20px',
				backgroundColor: 'white',
				margin: '0 auto',
			}}
		>
			{t('Please note - you can edit each nutrient and set it as')}{' '}
			<BoldText text={t('Required')} />,{' '}
			{t(
				'that will determine whether a recipe will appear in your result, whereas',
			)}{' '}
			<BoldText text={t('Preferred')} />{' '}
			{t('merely influences the score of each recipe shown in those results')}.
		</Box>
	);
};

export default ExplanatoryNote;
