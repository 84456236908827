import { UserTypeEnum } from './UserType.enum';
import { StatusType } from './enum';

type ENV_MODE = 'development' | 'staging' | 'production';
export const APP_API_URL =
	process.env.REACT_APP_API_URL || 'https://dev.url.comes.here/api';
export const basicDomain = process.env.DOMAIN || '';
export const APP_VERSION = process.env.REACT_APP_VERSION || '';
export const PAYPAL_CLIENT_ID_SEND_BOX =
	process.env.REACT_APP_PAYPAL_CLIENT_ID_SEND_BOX;
export const ReCAPTCHA_KEY = process.env.REACT_APP_ReCAPTCHA_KEY || '';
export const VIRTUAL_PRACTITIONER_EMAIL =
	process.env.REACT_APP_VIRTUAL_PRACTITIONER_EMAIL || '';
export const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID || '';
export const APP_GOOGLE_MAP_API_KEY = 'AIzaSyDs3PuvlX0H3B6hnvo4FmUEhP25dUg7fRI';
export const APP_PUBLIC_URL = process.env.PUBLIC_URL || '';
export const IS_PRODUCTION = process.env.NODE_ENV !== 'production';
export const IS_DEVELOPMENT = process.env.REACT_APP_ENV === 'development';
export const REACT_APP_MODE: ENV_MODE = process.env.REACT_APP_MODE as ENV_MODE;
export const APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL || '';
export const API_URL = IS_DEVELOPMENT
	? 'http://localhost:3000/api'
	: APP_API_URL;
export const LOGIN_HEADER_HEIGHT = 96;
export const DASHBOARD_MENU_WIDTH = 267;
export const DASHBOARD_TOP_BAR_HEIGHT = 80;
export const DASHBOARD_TOP_BAR_WIDTH = 1200;
export const DASHBOARD_MENU_CLOSE_WIDTH = 96;
export const DASHBOARD_TOP_MENU_CLOSE_WIDTH = 48;

export const siteFilterOptions = [
	'',
	'Wineshop in Town',
	'Tel Aviv Hospital',
	'Ramawat Mall ',
];
export const statusFilterOptions = [
	'',
	'Approved',
	'In Examination',
	'New Request',
];
export const requestChangeRequestTypeFilterOptions = [
	'',
	'Add New Site',
	'User Editing',
	'New Mobile User',
	'Delete',
];

export const requestChangeStatusFilterOptions = [
	'',
	'Approved',
	'New Change Request',
	'Rejected',
	'In Examination',
];

export const productFilterOptions = ['', 'Repair Service Renewal'];
export const customerServiceFilterOptions = [''];
export const serviceRequestStatusFilterOptions = [
	'',
	'New',
	'Taken Care of',
	'Irrelevant',
];

export const requestTypeFilterOptions = ['', 'New Site Request'];

export const technicalRequestTypeFilterOptions = [
	'',
	'Installation',
	'Repair of the alarm system',
	'Camera system repair',
];

export const technicalRequestStatusFilterOptions = [
	'',
	'New',
	'Taken care of',
	'Irrelevant',
];

export const purchaseStatusFilterOptions = [
	'',
	'New',
	'Inserted for billing',
	'Credit Charged',
	'Cancelled',
];

export const cityFilterOptions = ['Haifa'];

export const readingTypeFilterOptions = [
	'',
	'Installation',
	'Repair of the alarm system',
	'Camera System Repair',
];

export const serviceRequestType = [
	'',
	'Sales Enquiries',
	'Financial Inquiries',
	'Technical Support',
	'Public Inquiries',
];

export const technicalStatusFilterOptions = ['', 'New', 'Closed', 'Cancelled'];

export const changeRequestStatusFilterOptions = [
	'',
	'Approved',
	'In Examination',
	'New Request',
	'Rejected',
];

export const callTypeFilterOptions = [
	'',
	'Sales',
	'Financial Inquiries',
	'Technical Support',
	'Public Inquiries',
	'Financial Inquiries',
];

export const statusTypeFilterOptions = [
	{ id: StatusType.NEW, value: 'New' },
	{ id: StatusType.CLOSED, value: 'Closed' },
	{ id: StatusType.CANCELED, value: 'Canceled' },
	{ id: StatusType.IRRELEVENT, value: 'Irrelevent' },
	{ id: StatusType.TAKEN_CARE_OF, value: 'Taken care of' },
];

export const userStatusFilterOptions = ['', 'Active', 'Off'];
export const permissionFilterOptions = [
	{ id: '', value: '' },
	{ id: UserTypeEnum.MANAGER, value: 'Manager' },
	{ id: UserTypeEnum.SECRETARY, value: 'Secretary' },
];

export const positionFilterOptions = ['', 'Sales', 'New user'];
