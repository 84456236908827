import { Button } from '@mui/material';
import React from 'react';
import clsx from 'clsx';
import { useStyles } from '../style';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../containers/Help/redux/slice';
import { selectFaqListUser, selectOpenFaq } from '../redux/selector';
import { t } from 'i18next';

type Props = {};

const HelpButton = (props: Props) => {
	const useFaqList = useSelector(selectFaqListUser);
	const useOpenFaq = useSelector(selectOpenFaq);
	const styles = useStyles();
	const dispatch = useDispatch();

	const setOpenFaq = () => {
		if (!useFaqList?.length) {
			dispatch(actions.getFaqListUser());
		}
		dispatch(actions.setOpenFaq(true));
	};

	return (
		<Button
			onClick={() => {
				setOpenFaq();
			}}
			className={clsx([styles.helpButton])}
		>
			{!useOpenFaq ? t('Need help?') : t('Close help')}
		</Button>
	);
};

export default HelpButton;
