import { Box, Button, Typography } from '@mui/material';
import CustomModal from 'app/components/CustomModal';
import {
	useResetNutrients,
	useResetRestrictions,
	useResetVitamins,
} from 'app/containers/Patients/PatientDetails/utils';
import { IconBluePlus } from 'assets/images';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/slice';
import { useStyles } from './style';
import { t } from 'i18next';

export function ResetTemplate({
	setNutrients,
	setVitaminMinerals,
	setPractitionerExcluded,
	setPractitionerIncluded,
	setPractitionerIngredientsWarning,
	restrictions,
	setRestrictions,
}) {
	const { resetNutrients } = useResetNutrients(setNutrients);
	const { resetVitamins } = useResetVitamins(setVitaminMinerals);
	const { resetRestrictions } = useResetRestrictions(
		restrictions,
		setRestrictions,
	);

	const styles = useStyles();
	const dispatch = useDispatch();
	const [openResetTemplateName, setOpenResetTemplateName] = useState(false);
	const handleResetTemplate = () => {
		setPractitionerExcluded([]);
		setPractitionerIncluded([]);
		setPractitionerIngredientsWarning([]);
		resetRestrictions();
		resetNutrients();
		resetVitamins();
		setOpenResetTemplateName(false);
	};
	return (
		<Box className={clsx(styles.root)}>
			<Button
				className={clsx(styles.button)}
				onClick={() => {
					dispatch(actions.setAddTemplateError(''));
					dispatch(actions.setAddTemplateStatus(''));
					setOpenResetTemplateName(true);
				}}
			>
				{t('Reset profile.button')}
			</Button>
			<CustomModal
				btnTxt={t('Reset')}
				openModal={openResetTemplateName}
				onSubmit={() => handleResetTemplate()}
				setOpen={() => {
					setOpenResetTemplateName(false);
				}}
				Icon={IconBluePlus}
				heading={t('Reset profile.heading')} //Reset profile
				secondHeading=""
				modalClass={[styles.saveNameMClass]}
			>
				<Typography>
					{t('Reset profile.typography')}
					{/* You are about to reset the profile, the existing information is
					about to be lost. Are you sure you want to reset? */}
				</Typography>
			</CustomModal>
		</Box>
	);
}
