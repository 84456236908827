import {
	Box,
	Button,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../style';

const EditableTable = ({ tableData, handleSave, tableBody }) => {
	const styles = useStyles();
	const [rows, setRows] = useState<any>([]);

	useEffect(() => {
		setRows(tableData);
	}, [tableData]);

	const handleChange = (index, field, value) => {
		setRows(prevRows =>
			prevRows?.map((row, i) =>
				i === index ? { ...row, [field]: value } : row,
			),
		);
	};
	const numOfColumn = tableBody?.length;
	const columnWidth = 100 / numOfColumn;
	return (
		<>
			<Paper sx={{ padding: 2 }}>
				<Box className={clsx(styles.buttonBox)}>
					<Button
						onClick={() => handleSave(rows)}
						className={clsx(styles.btn)}
						variant="contained"
					>
						Save changes
					</Button>
				</Box>

				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								{tableBody?.map(header => (
									<TableCell
										key={header?.title}
										style={{ width: `${columnWidth}%`, fontWeight: 700 }}
									>
										{header?.title}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows?.map((row, index) => (
								<TableRow key={index}>
									{tableBody?.map((tableBody, i) => {
										const fieldName = tableBody?.field;
										return (
											<TableCell key={i} style={{ width: `${columnWidth}%` }}>
												{!tableBody?.isEdit ? (
													row[fieldName]
												) : (
													<TextField
														multiline
														fullWidth
														variant="outlined"
														value={row[fieldName]}
														onChange={e =>
															handleChange(index, fieldName, e.target.value)
														}
													/>
												)}
											</TableCell>
										);
									})}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	);
};

export default EditableTable;
