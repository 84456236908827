import { Box, Button, Checkbox, Grid, InputLabel } from '@mui/material';
import Heading from 'app/components/Heading';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from '../../components/Exclude/styles';
import AutocompleteIncludeExclude, {
	IngredientSelection,
} from '../Autocomplete';
import Exclude from '../Exclude/Exclude';
import theme from 'styles/theme/mui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { t } from 'i18next';

interface AutocompleteIncludeExcludeProps {
	practitionerExcluded: any;
	practitionerIncluded: any;
	practitionerIngredientsWarning: any;
	ingredientsList: any;
	ingredientSearchTerm: string;
	setPractitionerIngredients: any;
	practitionerIngredients: any;
	autocompleteType: IngredientSelection;
	title: string;
	subtitle: string;
	setIsWarningTypeCategories?: any;
	isWarningTypeCategories?: any;
	restrictions?: any;
	setRestrictions?: any;
	infoText: string;
	typeIngredientSelection: any;
	setTypeIngredientSelection: any;
}

const AutocompleteBox = ({
	practitionerExcluded,
	practitionerIncluded,
	practitionerIngredientsWarning,
	ingredientsList,
	ingredientSearchTerm,
	setPractitionerIngredients,
	practitionerIngredients,
	autocompleteType,
	title,
	subtitle,
	setIsWarningTypeCategories,
	isWarningTypeCategories,
	restrictions,
	setRestrictions,
	infoText,
	typeIngredientSelection,
	setTypeIngredientSelection,
}: AutocompleteIncludeExcludeProps) => {
	const styles = useStyles();
	const onChecked = optionId => {
		let _restrictions = restrictions || [];
		_restrictions = _restrictions.map(restriction => {
			if (optionId === restriction.optionId) {
				restriction.select = !restriction.select;
			}
			return restriction;
		});
		setRestrictions(_restrictions);
	};
	return (
		<Grid item xs={12} xl={4} lg={4} md={4}>
			<Heading subtitle={subtitle} title={title} infoText={infoText}>
				<InfoOutlinedIcon />
			</Heading>
			{autocompleteType !== 'Warning' ? (
				<Box className={clsx(styles.warningBox)}>
					<Box
						sx={{
							backgroundColor: '#EBE6F3',
						}}
						className={clsx(styles.warningDisableButton)}
					>
						<p className={clsx(styles.warningButtonText)}>{t('Foods')}</p>
					</Box>
					<Box className={clsx(styles.warningDisableButton)} />
				</Box>
			) : (
				<Box className={clsx(styles.warningBox)}>
					<Button
						onClick={() => {
							setIsWarningTypeCategories(false);
						}}
						sx={{
							backgroundColor: isWarningTypeCategories
								? theme.palette.primary.contrastText
								: '#EBE6F3',
						}}
						className={clsx(styles.warningButton)}
					>
						<p className={clsx(styles.warningButtonText)}>{t('Foods')}</p>
					</Button>
					<Button
						onClick={() => {
							setIsWarningTypeCategories(true);
						}}
						sx={{
							backgroundColor: !isWarningTypeCategories
								? theme.palette.primary.contrastText
								: '#EBE6F3',
						}}
						className={clsx(styles.warningButton)}
					>
						<p className={clsx(styles.warningButtonText)}>
							{t('Intolerances')}
						</p>
					</Button>
				</Box>
			)}
			{isWarningTypeCategories ? (
				<Exclude>
					{restrictions?.map((restriction, i) => (
						<Box className={clsx(styles.checkboxBox)} key={i}>
							<Checkbox
								className={styles.customCheckbox}
								checked={restriction?.select || false}
								onClick={() => {
									onChecked(restriction?.optionId);
								}}
							/>
							<InputLabel className={clsx(styles.checkboxLabel)}>
								{restriction?.optionText}
							</InputLabel>
						</Box>
					))}
				</Exclude>
			) : (
				<Exclude>
					<AutocompleteIncludeExclude
						practitionerExcluded={practitionerExcluded}
						practitionerIncluded={practitionerIncluded}
						practitionerIngredientsWarning={practitionerIngredientsWarning}
						ingredientsList={ingredientsList}
						ingredientSearchTerm={ingredientSearchTerm}
						setPractitionerIngredients={setPractitionerIngredients}
						practitionerIngredients={practitionerIngredients}
						autocompleteType={autocompleteType}
						typeIngredientSelection={typeIngredientSelection}
						setTypeIngredientSelection={setTypeIngredientSelection}
						restrictions={restrictions}
					/>
				</Exclude>
			)}
		</Grid>
	);
};

export default AutocompleteBox;
