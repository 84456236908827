export const countryToLanguageMap: Record<string, string> = {
	// English-speaking countries
	US: 'en', // United States
	GB: 'en', // United Kingdom
	CA: 'en', // Canada
	AU: 'en', // Australia
	IN: 'en', // India (English widely used)

	// Spanish-speaking countries
	ES: 'es', // Spain
	MX: 'es', // Mexico
	AR: 'es', // Argentina
	CO: 'es', // Colombia
	CL: 'es', // Chile

	// French-speaking countries
	FR: 'fr', // France

	// German-speaking countries
	DE: 'de', // Germany
	AT: 'de', // Austria
	CH: 'de', // Switzerland (German region)

	// Chinese-speaking countries
	CN: 'zh', // China (Simplified Chinese)
	TW: 'zh-TW', // Taiwan (Traditional Chinese)
	HK: 'zh-TW', // Hong Kong (Traditional Chinese)

	// Portuguese-speaking countries
	PT: 'pt', // Portugal
	BR: 'pt-BR', // Brazil

	// Arabic-speaking countries
	SA: 'ar', // Saudi Arabia
	AE: 'ar', // United Arab Emirates
	EG: 'ar', // Egypt
	MA: 'ar', // Morocco

	// Japanese-speaking country
	JP: 'ja', // Japan

	// Korean-speaking country
	KR: 'ko', // South Korea

	// Russian-speaking countries
	RU: 'ru', // Russia
	UA: 'ru', // Ukraine (Russian widely spoken)

	// Italian-speaking countries
	IT: 'it', // Italy

	// Dutch-speaking countries
	NL: 'nl', // Netherlands
	BE: 'nl', // Belgium (Flemish)

	// Turkish-speaking country
	TR: 'tr', // Turkey

	// Polish-speaking country
	PL: 'pl', // Poland

	// Swedish-speaking country
	SE: 'sv', // Sweden

	IL: 'he',

	// Default fallback
	DEFAULT: 'en', // Use English as the fallback for unmapped countries
};
