import { useSelector } from 'react-redux';
import { ThemeKeyType } from './types';
import { selectDirection } from './slice';

/* istanbul ignore next line */
export const isSystemDark = window?.matchMedia
	? window.matchMedia('(prefers-color-scheme: dark)')?.matches
	: undefined;

export function saveTheme(theme: ThemeKeyType) {
	window.localStorage && localStorage.setItem('selectedTheme', theme);
}

/* istanbul ignore next line */
export function getThemeFromStorage(): ThemeKeyType | null {
	return window.localStorage
		? (localStorage.getItem('selectedTheme') as ThemeKeyType) || null
		: null;
}
export const useIsRtl = () => {
	const direction = useSelector(selectDirection);
	const isRTL = direction === 'rtl';
	return isRTL;
};
