import {
	Alert,
	AlertTitle,
	Box,
	Button,
	Container,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import SideBar from 'app/components/Sidebar';
import UserRightBar from 'app/components/UserRightBar';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CustomModal from 'app/components/CustomModal';
import Header from 'app/components/Header';
import Content from 'app/components/content';
import { IconBluePlus, IconTrashRed } from 'assets/images';
import {
	selectActionType,
	selectAddQuestionError,
	selectAddTopicPopupOpen,
	selectDeleteTopicPopupOpen,
	selectFaqList,
	selectSelectedQuestion,
} from '../../redux/selector';
import { actions } from '../../redux/slice';
import { useStyles } from '../../style';
import { FaqObj } from '../../types';
import TopicsListTable from './TopicsTable';
import { numOfClients } from 'app/containers/Practitioner/PractitionerTable';
import { DropzoneImg } from 'app/components/Dropzone/DropzoneImg';
import { t } from 'i18next';
export const initValue: FaqObj = {
	topic: '',
	question: '',
	answer: '',
	order: null,
	image: null,
};
const FaqList = () => {
	const useAddTopicPopupOpen = useSelector(selectAddTopicPopupOpen);
	const useDeleteTopicPopupOpen = useSelector(selectDeleteTopicPopupOpen);
	const useSelectAddQuestionError = useSelector(selectAddQuestionError);
	const useSelectSelectedQuestion = useSelector(selectSelectedQuestion);
	const useSelectActionType = useSelector(selectActionType);
	const useFaqList = useSelector(selectFaqList);
	const [customTopic, setCustomTopic] = useState('');
	const CUSTOM_TOPIC = 'custom';

	useEffect(() => {
		callback();
	}, []);

	const dispatch = useDispatch();

	const styles = useStyles();
	const callback = () => {
		dispatch(actions.getFaqListAdmin());
	};

	const onSubmitFormAddTemplate = () => {
		const question = {
			...useSelectSelectedQuestion,
			topic:
				useSelectSelectedQuestion.topic === CUSTOM_TOPIC
					? customTopic
					: useSelectSelectedQuestion.topic,
		};
		dispatch(
			actions.addNewQuestion({
				question,
				callback: callback,
			}),
		);
	};

	const handleSelectChange = e => {
		if (e.target.value === CUSTOM_TOPIC) {
			setCustomTopic('');
		}
		handleFieldChange(e, 'topic');
	};

	const onSubmitFormDeleteQuestion = questionId => {
		dispatch(actions.deleteQuestion({ questionId, callback: callback }));
	};
	const handleFieldChange = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type,
	) => {
		dispatch(actions.setAddQuestionError(''));
		const { value } = evt.target;
		dispatch(
			actions.setSelectedQuestion({
				...useSelectSelectedQuestion,
				[type]: value,
			}),
		);
	};

	return (
		<Box display="flex" alignItems={'center'}>
			<Header />
			<Content>
				<Grid container spacing={2} className={clsx(styles._root)}>
					<Grid item lg={12} xs={12}>
						<Box display="flex" style={{ height: '100%' }}>
							<SideBar />
							<Container
								maxWidth={'xl'}
								sx={{ height: 'calc( 100vh - 80px )', overflowY: 'scroll' }}
							>
								<Box className={clsx(styles.wrapper)}>
									<Box
										component="div"
										display="flex"
										justifyContent={'space-between'}
										alignItems={'center'}
									>
										<Box component="div" />
										<Box className={clsx(styles.buttonBox)}>
											<Button
												onClick={() => {
													dispatch(actions.setAddTopicPopupOpen(true));
													dispatch(actions.setActionType('Add'));
													dispatch(actions.setSelectedQuestion(initValue));
												}}
												className={clsx(styles.btn)}
												variant="contained"
											>
												Add a new question
											</Button>
										</Box>
									</Box>

									<Box component="div" className={clsx(styles.TableBox)}>
										<TopicsListTable
											setDeleteTemplatePopupOpen={question => {
												dispatch(actions.setDeleteTopicPopupOpen(true));
												dispatch(actions.setSelectedQuestion(question));
											}}
											setEditTemplatePopupOpen={question => {
												dispatch(actions.setActionType('Edit'));
												dispatch(actions.setSelectedQuestion(question));
												dispatch(actions.setAddTopicPopupOpen(true));
											}}
											orderCallback={callback}
										/>
									</Box>
								</Box>
							</Container>
							<UserRightBar />
						</Box>
					</Grid>
				</Grid>
			</Content>
			<CustomModal
				btnTxt={t('Save')}
				openModal={useAddTopicPopupOpen}
				setOpen={() => {
					dispatch(actions.setAddTopicPopupOpen(false));
					dispatch(actions.setAddQuestionError(''));
					dispatch(actions.setSelectedQuestion(initValue));
				}}
				Icon={IconBluePlus}
				heading={`${useSelectActionType} ${t('a question')}`}
				secondHeading=""
				modalClass={[styles.patient]}
				onSubmit={onSubmitFormAddTemplate}
				disabled={
					!Boolean(useSelectSelectedQuestion.topic) ||
					!Boolean(useSelectSelectedQuestion.question) ||
					!Boolean(useSelectSelectedQuestion.answer)
				}
			>
				<Box component={`div`} className={styles.formGroup}>
					{useSelectAddQuestionError && (
						<Alert severity="error">
							<AlertTitle sx={{ padding: '0 10px' }}>
								{useSelectAddQuestionError}
							</AlertTitle>
						</Alert>
					)}
					<Box>
						<InputLabel className={styles.label}>{t('Topic')}</InputLabel>
						<Select
							value={useSelectSelectedQuestion.topic}
							name="templateType"
							id="templateType"
							fullWidth
							onChange={handleSelectChange}
							className={styles.selectInput}
						>
							{useFaqList
								?.filter(
									(option, index, self) =>
										index === self.findIndex(o => o.topic === option.topic),
								)
								.map(option => (
									<MenuItem value={option?.topic} key={option?.question}>
										{option?.topic}
									</MenuItem>
								))}
							<MenuItem value={CUSTOM_TOPIC}>Other...</MenuItem>
						</Select>
						{useSelectSelectedQuestion.topic === CUSTOM_TOPIC && (
							<TextField
								value={customTopic}
								onChange={e => setCustomTopic(e.target.value)}
								placeholder={t('Type your custom topic')}
								fullWidth
								className={styles.input}
							/>
						)}
					</Box>
					{useSelectActionType === 'Add' ? (
						<Box>
							<InputLabel className={styles.label}>{t('Order')}</InputLabel>
							<Select
								value={useSelectSelectedQuestion.order}
								name="templateTyp1e"
								id="templateTyp1e"
								fullWidth
								onChange={(e: any) => handleFieldChange(e, 'order')}
								className={styles.selectInput}
							>
								{numOfClients?.map((numOfClient, i) => (
									<MenuItem value={numOfClient} key={i}>
										{numOfClient}
									</MenuItem>
								))}
							</Select>
						</Box>
					) : null}
					<Box>
						<InputLabel className={styles.label}>{t('Question')}</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							type="name"
							fullWidth
							placeholder=""
							id="fullWidth2"
							value={useSelectSelectedQuestion.question}
							onChange={(e: any) => handleFieldChange(e, 'question')}
							name="fullName"
							rows={3}
							multiline
						/>
					</Box>
					<Box>
						<InputLabel className={styles.label}>{t('Answer')}</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							type="name"
							fullWidth
							placeholder=""
							id="fullWidth3"
							value={useSelectSelectedQuestion.answer}
							onChange={(e: any) => handleFieldChange(e, 'answer')}
							name="fullName"
							rows={5}
							multiline
						/>
					</Box>
					<Box className={styles.dropzoneImgBox}>
						<DropzoneImg />
					</Box>
				</Box>
			</CustomModal>
			<CustomModal
				btnTxt={t('Delete')}
				openModal={useDeleteTopicPopupOpen}
				setOpen={() => {
					dispatch(actions.setDeleteTopicPopupOpen(false));
					dispatch(actions.setAddQuestionError(''));
					dispatch(actions.setSelectedQuestion(initValue));
				}}
				Icon={IconTrashRed}
				heading={t('Delete this question')}
				secondHeading=""
				modalClass={[styles.TrashMClass]}
				onSubmit={() =>
					onSubmitFormDeleteQuestion(useSelectSelectedQuestion?._id)
				}
			>
				<Box component={`div`} className={styles.formGroup}>
					{useSelectAddQuestionError && (
						<Alert severity="error">
							<AlertTitle sx={{ padding: '0 10px' }}>
								{useSelectAddQuestionError}
							</AlertTitle>
						</Alert>
					)}
					<Box>
						<InputLabel className={styles.label}>{t('Question')}</InputLabel>
						<TextField
							className={styles.input}
							InputLabelProps={{ style: { color: '#645B70' } }}
							type="name"
							fullWidth
							id="fullWidth4"
							value={useSelectSelectedQuestion?.question}
							name="fullName"
							disabled={!Boolean(useSelectSelectedQuestion?.question)}
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									e.preventDefault();
								}
							}}
						/>
					</Box>
				</Box>
			</CustomModal>
		</Box>
	);
};

export default FaqList;
