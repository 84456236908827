import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DASHBOARD_TOP_BAR_HEIGHT } from 'utils/constants';

export const useStyles = makeStyles((theme: Theme) => ({
	modal: {
		'& .MuiBackdrop-root': {
			background: 'rgb(29 1 73 / 60%)',
		},
		boxShadow: 'none',
	},
	fbtn: {
		borderRadius: '40px',
		padding: '5px 24px',
		fontWeight: '700',
		color: theme.palette.primary.main,
		boxShadow: 'none',
	},
	fbtn1: {
		background: theme.palette.primary.main,
		color: `${theme.palette.common.white} !important`,
	},
	btn: {
		background: theme.palette.primary.main,
		borderRadius: '40px',
		color: theme.palette.common.white,
		padding: ' 8px 0px',
		width: '252px',
		height: '56px',
		marginBottom: '24px',
		fontWeight: '700',
	},
	popup: {
		direction: 'ltr',
		background: theme.palette.common.white,
		borderRadius: '32px',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '50%',
		padding: '54px 24px 24px',
		'@media (max-width:600px)': {
			minWidth: '90%',
		},
	},
	popupIcon: {
		position: 'absolute',
		top: ' 0',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '70px',
		height: '70px',
		borderRadius: '40px',
		background: theme.palette.common.white,
		'&:hover': {
			background: theme.palette.common.white,
		},
	},
	title: {
		color: theme.palette.secondary.dark,
		fontWeight: '700',
		marginBottom: '10px',
	},
	label: {
		paddingLeft: '24px',
		fontSize: '12px',
		color: theme.palette.primary.main,
		fontWeight: '700',
		marginBottom: '8px',
	},
	input: {
		'& .MuiOutlinedInput-root': {
			background: theme.palette.primary.light,
			borderRadius: '40px',
			paddingLeft: '20px',
			fontWeight: '500',
		},
		'& .MuiFormHelperText-root': {
			textAlign: 'right',
			color: theme.palette.primary.main,
			fontWeight: '700',
			fontSize: '12px',
		},
	},
	wrapper: { marginTop: DASHBOARD_TOP_BAR_HEIGHT },
	head: {
		width: '100%',
		'& .MuiBox-root': {
			marginBottom: '0',
		},
		'& .MuiButton-root': {
			marginBottom: '0',
		},
	},
	rightside: {
		height: '100%',
	},
}));
