import { Lock } from '@mui/icons-material';
import {
	Alert,
	AlertTitle,
	Button,
	Container,
	FormControl,
	FormLabel,
	InputAdornment,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import CustomModal from 'app/components/CustomModal';
import { TextField } from 'app/components/TextField';
import { PatientRepoSaga } from 'app/containers/Patients/redux/saga';
import {
	selectCountryCode,
	selectIsPrePaymentOpen,
} from 'app/containers/Patients/redux/selector';
import PaypalProvider from 'app/containers/Paypal/PaypalProvider';
import { IconBluePlus, IconLogo, ImageForgetPassword } from 'assets/images';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import {
	actions as patientActions,
	reducer,
	sliceKey,
} from '../../Patients/redux/slice';
import { selectSetAccountForm } from '../selectors';
import { actions } from '../slice';
import { useStyles } from './styles';
import { SET_PASSWORD } from 'app/containers/Paypal/PaypalButton';
import { t } from 'i18next';

type Props = {};

const SetPassword = (props: Props) => {
	useInjectReducer({ key: sliceKey, reducer });
	useInjectSaga({ key: sliceKey, saga: PatientRepoSaga });
	const navigate = useNavigate();
	const styles = useStyles();
	const dispatch = useDispatch();
	const isPrePaymentOpen = useSelector(selectIsPrePaymentOpen);
	const { password, confirmPassword, error, fullName, promoCode } =
		useSelector(selectSetAccountForm);
	const [searchParams, setSearchParams] = useSearchParams();
	const [disablePromoCode, setDisablePromoCode] = useState(true);
	const tokenId = searchParams.get('token');
	const [_fullName, setFullName] = useState(
		searchParams.get('fullName')?.replace(/-/g, ' '),
	);
	const [_promoCode, setPromoCode] = useState(searchParams.get('promoCode'));
	const useCountryCode = useSelector(selectCountryCode);

	useEffect(() => {
		if (tokenId) {
			localStorage.setItem('token', tokenId);
		}
	}, [tokenId]);
	useEffect(() => {
		if (_fullName) {
			dispatch(
				actions.updateSetAccountForm({ name: 'fullName', value: _fullName }),
			);
		}
		if (_promoCode) {
			dispatch(
				actions.updateSetAccountForm({ name: 'promoCode', value: _promoCode }),
			);
		}
		dispatch(patientActions.getCountryCodeByIp());
		setDisablePromoCode(_promoCode ? true : false);
	}, []);

	useEffect(() => {
		setFullName(fullName);
	}, [fullName]);

	useEffect(() => {
		setPromoCode(promoCode);
	}, [promoCode]);

	const sendError = err => {
		dispatch(
			actions.updateSetAccountForm({
				name: 'error',
				value: err,
			}),
		);
		return false;
	};
	const validateFields = () => {
		if (!fullName) {
			return sendError('Please make sure to fill out the full name field');
		} else if (!password) {
			return sendError('Please make sure to fill out the password field');
		} else if (password !== confirmPassword) {
			return sendError(`Password doesn't match`);
		} else return true;
	};

	const onSuccessPaypal = () => {
		navigate(`/paypal-offer?token=${tokenId}`);
	};
	const onSuccessStripe = link => {
		dispatch(
			patientActions.togglePrePaymentPopup({ visible: true, link: link }),
		);
	};

	const onSuccessFree = link => {
		navigate(link);
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (validateFields()) {
			dispatch(
				actions.setPassword({
					location: useCountryCode?.code || 'US',
					token: searchParams.get('token'),
					paypalCallback: onSuccessPaypal,
					stripeCallback: onSuccessStripe,
					freeCallback: onSuccessFree,
				}),
			);
		}
	};
	if (!tokenId || tokenId?.length <= 0) {
		return (
			<Navigate
				to={{
					pathname: '/login',
				}}
			/>
		);
	}
	if (tokenId) {
		dispatch(
			actions.updateSetAccountForm({ name: 'setAccountToken', value: tokenId }),
		);
	}
	const onChange = e => {
		const { name, value } = e.target;
		dispatch(actions.updateSetAccountForm({ name, value }));
		dispatch(actions.updateSetAccountForm({ name: 'error', value: '' }));
	};
	const onSuccessStatusCallback = () => {
		dispatch(
			patientActions.togglePrePaymentPopup({ visible: false, link: null }),
		);
		// TODO: Maybe show the user that the payment was successful
		navigate('/login');
	};

	const closePrePaymentPatient = () => {
		dispatch(
			patientActions.togglePrePaymentPopup({ visible: false, link: null }),
		);
	};
	const onClickLater = () => {
		onSuccessStatusCallback();
	};
	return (
		<Box display="flex" alignItems={'center'} className={clsx(styles.root)}>
			<Container className={clsx(styles.container)}>
				<Box>
					<img
						src={ImageForgetPassword}
						alt="right bg"
						className={clsx(styles.img)}
					/>
					<Box component={`div`} className={clsx(styles.content)}>
						<Box component={`div`} className={clsx(styles.head)}>
							<img src={IconLogo} alt="logo" className={clsx(styles.logo)} />
							<Typography variant={`h6`} className={clsx(styles.topTitle)}>
								{t('Hello')} {_fullName},
							</Typography>
							<Typography variant={`h6`} className={clsx(styles.topTxt)}>
								{t('Set your account password')}
							</Typography>
						</Box>

						<Box
							component="form"
							noValidate
							sx={{
								display: 'grid',
								gridTemplateColumns: { sm: '1fr' },
								gap: 1,
								columnGap: 7,
							}}
							className={clsx(styles.loginForm)}
						>
							{error && (
								<Alert className={styles.error} severity="error">
									<AlertTitle sx={{ padding: '0 10px' }}>{error}</AlertTitle>
								</Alert>
							)}
							<FormControl variant="outlined">
								<Box component={`div`} className={clsx([styles.formGroup])}>
									<FormLabel className={clsx([styles.title])}>
										{t('Password')}
									</FormLabel>
								</Box>
								<TextField
									height={51}
									variant="outlined"
									margin="normal"
									required
									type="password"
									fullWidth
									id="password"
									placeholder={t('Password')}
									name="password"
									autoComplete="password"
									value={password}
									autoFocus
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Lock />
											</InputAdornment>
										),
									}}
									onChange={onChange}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}
								/>
							</FormControl>
							<FormControl variant="outlined">
								<Box component={`div`} className={clsx([styles.formGroup])}>
									<FormLabel className={clsx([styles.title])}>
										{t('Confirm password')}
									</FormLabel>
								</Box>
								<TextField
									height={51}
									variant="outlined"
									margin="normal"
									type="password"
									required
									fullWidth
									id="confirmPassword"
									placeholder={t('Confirm password')}
									name="confirmPassword"
									autoComplete="confirmPassword"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<Lock />
											</InputAdornment>
										),
									}}
									value={confirmPassword}
									onChange={onChange}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}
								/>
							</FormControl>
							<FormControl variant="outlined">
								<Box component={`div`} className={clsx([styles.formGroup])}>
									<FormLabel className={clsx([styles.title])}>
										{t(
											'Promo code (If you have a promotion code, please enter it here)',
										)}
									</FormLabel>
								</Box>
								<TextField
									height={51}
									variant="outlined"
									margin="normal"
									required
									fullWidth
									id="promoCode"
									placeholder={t('Promo code provided by anydish')}
									name="promoCode"
									value={_promoCode}
									onChange={onChange}
									disabled={disablePromoCode}
									onKeyDown={(e: any) => {
										if (e.key === 'Enter') {
											e.preventDefault();
										}
									}}
								/>
							</FormControl>
							<FormControl sx={{ width: '100' }} variant="outlined">
								<Box component={`div`} className={clsx([styles.btnBox])}>
									<Button
										type="button"
										variant="contained"
										onClick={handleSubmit}
									>
										{t('Continue')}
									</Button>
								</Box>
							</FormControl>
						</Box>
					</Box>
				</Box>
				<CustomModal
					btnTxt={t('Later')}
					openModal={isPrePaymentOpen.visible}
					setOpen={closePrePaymentPatient}
					Icon={IconBluePlus}
					heading={`${t('Hi')} ${_fullName}, ${t("You're almost there!")}`}
					secondHeading={''}
					modalClass={[styles.ReassignMClass]}
					onSubmit={onClickLater}
					onlyOneButton={true}
				>
					<p className={styles.labelText}>
						{t(
							'To finalize your registration and grant your first client access to anydish, complete your first payment via',
						)}{' '}
						{isPrePaymentOpen?.link?.includes('stripe')
							? t('Stripe')
							: t('PayPal or your credit/debit card')}
						.
					</p>
					<p className={styles.labelText}>
						{t('Click')}{' '}
						{isPrePaymentOpen?.link?.includes('stripe')
							? t('Complete Registration & Pay')
							: t('the proper button')}{' '}
						{t('to proceed')}.
					</p>
					{isPrePaymentOpen?.link?.includes('stripe') && (
						<Box className={clsx(styles.btnBoxPopup)}>
							<Button
								onClick={() => {
									window.location.replace(isPrePaymentOpen.link);
								}}
								className={clsx(styles.btn)}
								variant="contained"
							>
								{t('Complete Registration & Pay')}
							</Button>
						</Box>
					)}
					{isPrePaymentOpen?.link?.includes('paypal') && (
						<Box className={clsx(styles.btnBoxPopup)}>
							<PaypalProvider
								onSuccessStatusCallback={onSuccessStatusCallback}
								link={isPrePaymentOpen.link}
								sourceComponent={SET_PASSWORD}
							/>
						</Box>
					)}
				</CustomModal>
			</Container>
		</Box>
	);
};

export default SetPassword;
