import { RootState } from 'types';

import { createSelector } from '@reduxjs/toolkit';

import { initialState } from './slice';

const selectDomain = (state: RootState) =>
	state.translationsState || initialState;
export const selectError = createSelector([selectDomain], state => state.error);
export const selectLanguageData = createSelector(
	[selectDomain],
	state => state.languageData,
);
export const selectIsLoading = createSelector(
	[selectDomain],
	state => state.isLoading,
);
export const selectOpenAddNewValuePopup = createSelector(
	[selectDomain],
	state => state.openAddNewValuePopup,
);
export const selectFormAddNewValue = createSelector(
	[selectDomain],
	state => state.formAddNewValue,
);
